/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DirectionModel } from '../models/DirectionModel';
import type { reestreduorg__api__direction__Direction } from '../models/reestreduorg__api__direction__Direction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DirectionService {

    /**
     * Get Directions
     * Получение всех направлений.
     * @returns reestreduorg__api__direction__Direction Successful Response
     * @throws ApiError
     */
    public static getDirectionsApiV1DirectionGet(): CancelablePromise<Array<reestreduorg__api__direction__Direction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/direction/',
        });
    }

    /**
     * Set Direction
     * Создание направления.
     * @param requestBody
     * @returns reestreduorg__api__direction__Direction Successful Response
     * @throws ApiError
     */
    public static setDirectionApiV1DirectionPost(
        requestBody: DirectionModel,
    ): CancelablePromise<reestreduorg__api__direction__Direction> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/direction/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Direction By Id
     * Редактироване направления.
     * @param directionId
     * @param requestBody
     * @returns reestreduorg__api__direction__Direction Successful Response
     * @throws ApiError
     */
    public static setDirectionByIdApiV1DirectionDirectionIdPut(
        directionId: string,
        requestBody: DirectionModel,
    ): CancelablePromise<reestreduorg__api__direction__Direction> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/direction/{direction_id}/',
            path: {
                'direction_id': directionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Direction
     * Удаление направления.
     * @param directionId
     * @returns reestreduorg__api__direction__Direction Successful Response
     * @throws ApiError
     */
    public static unsetDirectionApiV1DirectionDirectionIdDelete(
        directionId: string,
    ): CancelablePromise<reestreduorg__api__direction__Direction> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/direction/{direction_id}/',
            path: {
                'direction_id': directionId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

}
