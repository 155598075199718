<template>

<div class="frame">
<div class="card">

    <!-- <div class="field">
    <h3>направления</h3>
    </div> -->


    <h3>Направления: </h3>
    <div class="formgrid grid">
    <h5 v-for="dirs in eduOrgStore.searchingEdu[0].directions" :key="dirs" class="dirstext ml-2 mr-2 field">{{dirs.name}}</h5>
    </div>

<h3>Электроный адрес: </h3>
<a :href="eduOrgStore.searchingEdu[0].url">{{ eduOrgStore.searchingEdu[0].url }}</a>
</div>

<h3>Номера телефонов:</h3>
          <DataTable :value="eduOrgStore.searchingEdu[0].phones" class="mb-2 cardInfo infoPhoneEndEmails">
            <tColumn field="value" header="Номер телефона"></tColumn>
            <tColumn field="description" header="Описание"></tColumn>
          </DataTable>

           <h3>Электронные почты:</h3>
          <DataTable :value="eduOrgStore.searchingEdu[0].emails" class="mb-2 cardInfo infoPhoneEndEmails">
            <tColumn field="value" header="Email"></tColumn>
          </DataTable>

 <div class="font-medium mt-10">
            <h3>Адрес: {{ eduOrgStore.searchingEdu[0].address }}</h3>

</div>

          </div>

</template>

<script setup>
import { useEduOrgStore } from '../stores/EduOrgStore';

const eduOrgStore = useEduOrgStore()
console.log(eduOrgStore.searchingEdu[0].name);


</script>

<style scoped>
.dirstext {
  background-color: rgb(215, 215, 215);
  font-weight: bold;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 20px;
  color: rgb(67, 67, 67);
  margin: 0;
  margin-bottom: 5px;
}
.cardInfo {
  font-weight: bold;
  max-width: 50%;

}
.infoPhoneEndEmails {
  box-shadow: 4px 8px 10px 5px rgba(34, 60, 80, 0.2);
}


</style>