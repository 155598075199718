/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Person } from '../models/Person';
import type { PersonCreateModel } from '../models/PersonCreateModel';
import type { PersonPasswordModel } from '../models/PersonPasswordModel';
import type { PersonUpdateModel } from '../models/PersonUpdateModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PersonService {

    /**
     * Get Persons
     * Получение всех персон.
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static getPersonsApiV1PersonGet(): CancelablePromise<Array<Person>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/person/',
            errors: {
                401: `Authorization failed`,
            },
        });
    }

    /**
     * Set Person
     * Создание персоны.
     * @param requestBody
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static setPersonApiV1PersonPost(
        requestBody: PersonCreateModel,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/person/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Current Person
     * Получение текущей персоны.
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static getCurrentPersonApiV1PersonCurrentGet(): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/person/current/',
            errors: {
                401: `Authorization failed`,
            },
        });
    }

    /**
     * Get Person
     * Получение персоны.
     * @param personId
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static getPersonApiV1PersonPersonIdGet(
        personId: string,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/person/{person_id}/',
            path: {
                'person_id': personId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Person Name By Id
     * Редактироване персоны.
     * @param personId
     * @param requestBody
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static setPersonNameByIdApiV1PersonPersonIdPut(
        personId: string,
        requestBody: PersonUpdateModel,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/person/{person_id}/',
            path: {
                'person_id': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Person
     * Удаление персоны.
     * @param personId
     * @returns Person Successful Response
     * @throws ApiError
     */
    public static unsetPersonApiV1PersonPersonIdDelete(
        personId: string,
    ): CancelablePromise<Person> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/person/{person_id}/',
            path: {
                'person_id': personId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Person Password By Id
     * Редактирование пароля персоны.
     * @param personId
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static setPersonPasswordByIdApiV1PersonPersonIdPasswordPut(
        personId: string,
        requestBody: PersonPasswordModel,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/person/{person_id}/password/',
            path: {
                'person_id': personId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

}
