
import { defineStore } from "pinia";
import { LevelService, DirectionService, OrganizationService } from "../client";

interface forLevelsStore {
    /**
     * Уровни образования
     */
    levels: any[],
    /**
     * Образовательные направления
     */
    directions: any[],
    /**
     * Выбранные направления
     * */
    selectedValue: any,
    /**
     * Выбранные направления для добавления
     */
    selectedValueAdd: any
    /**
     * Сгрупированый списко направлений
     *  */
    groupedTypes: any[],
}

export const useLevelsStore = defineStore({
    id: 'levelTypes',
    state: (): forLevelsStore => ({
        levels: [],
        directions: [],
        selectedValue: null,
        groupedTypes: [],
        selectedValueAdd: null
    }),
    getters: {
        getGroupedTypes: (state) => state.groupedTypes,
    },
    actions: {
        /**
         * Получение всех типов образования 
         */
        getTypes() {

            LevelService.getLevelsApiV1LevelGet()
                .then(resLevl => {
                    this.levels = resLevl
                    DirectionService.getDirectionsApiV1DirectionGet()
                        .then(resDir => {
                            this.directions = resDir
                            const gTypes: any[] = []
                            this.levels.forEach(lvl => {
                                const gType = {
                                    label: lvl.name,
                                    code: lvl.id,
                                    items: new Array<any>()
                                }
                                this.directions.forEach(dir => {
                                    if (gType.code == dir.level_id) {
                                        gType.items.push({
                                            label: dir.name,
                                            value: dir.id
                                        })
                                    }
                                })
                                gTypes.push(gType)
                            });
                            this.groupedTypes = gTypes
                        })
                })
        },
        /**
         * Установка направления образования на организацию
         * @param idOrg ID организации
         * @param idDir ID образовательного направления
         */
        setDirectionInOrg(idOrg: string, idDir: string) {
            OrganizationService.setDirectionInOrgApiV1OrgOrgIdDirectionDirectionIdPost(idOrg, this.selectedValueAdd.items.code)
                .then(res => {
                    console.log(res)
                })
        }
    }
})
