/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Email } from '../models/Email';
import type { EmailModel } from '../models/EmailModel';
import type { Org } from '../models/Org';
import type { OrgModel } from '../models/OrgModel';
import type { Phone } from '../models/Phone';
import type { PhoneModel } from '../models/PhoneModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Get Orgs
     * Получение всех организаций.
     * @returns Org Successful Response
     * @throws ApiError
     */
    public static getOrgsApiV1OrgGet(): CancelablePromise<Array<Org>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/org/',
        });
    }

    /**
     * Set Org
     * Создание организации.
     * @param requestBody
     * @returns Org Successful Response
     * @throws ApiError
     */
    public static setOrgApiV1OrgPost(
        requestBody: OrgModel,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/org/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Org By Id
     * Редактироване организации.
     * @param orgId
     * @param requestBody
     * @returns Org Successful Response
     * @throws ApiError
     */
    public static setOrgByIdApiV1OrgOrgIdPut(
        orgId: string,
        requestBody: OrgModel,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/org/{org_id}/',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Org
     * Удаление организации.
     * @param orgId
     * @returns Org Successful Response
     * @throws ApiError
     */
    public static unsetOrgApiV1OrgOrgIdDelete(
        orgId: string,
    ): CancelablePromise<Org> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/org/{org_id}/',
            path: {
                'org_id': orgId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Email
     * Создание электронной почты организации.
     * @param orgId
     * @param requestBody
     * @returns Email Successful Response
     * @throws ApiError
     */
    public static setEmailApiV1OrgOrgIdEmailPost(
        orgId: string,
        requestBody: EmailModel,
    ): CancelablePromise<Email> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/org/{org_id}/email/',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Email
     * Удаление электронной почты организации.
     * @param orgId
     * @param emailId
     * @returns Email Successful Response
     * @throws ApiError
     */
    public static unsetEmailApiV1OrgOrgIdEmailEmailIdDelete(
        orgId: string,
        emailId: string,
    ): CancelablePromise<Email> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/org/{org_id}/email/{email_id}/',
            path: {
                'org_id': orgId,
                'email_id': emailId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Phone
     * Создание телефона организации.
     * @param orgId
     * @param requestBody
     * @returns Phone Successful Response
     * @throws ApiError
     */
    public static setPhoneApiV1OrgOrgIdPhonePost(
        orgId: string,
        requestBody: PhoneModel,
    ): CancelablePromise<Phone> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/org/{org_id}/phone/',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Phone
     * Удаление телефона организации.
     * @param orgId
     * @param phoneId
     * @returns Phone Successful Response
     * @throws ApiError
     */
    public static unsetPhoneApiV1OrgOrgIdPhonePhoneIdDelete(
        orgId: string,
        phoneId: string,
    ): CancelablePromise<Phone> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/org/{org_id}/phone/{phone_id}/',
            path: {
                'org_id': orgId,
                'phone_id': phoneId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Direction In Org
     * Добавление направления в организацию.
     * @param orgId
     * @param directionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static setDirectionInOrgApiV1OrgOrgIdDirectionDirectionIdPost(
        orgId: string,
        directionId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/org/{org_id}/direction/{direction_id}/',
            path: {
                'org_id': orgId,
                'direction_id': directionId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Direction From Org
     * Удаление направления из организации.
     * @param orgId
     * @param directionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static unsetDirectionFromOrgApiV1OrgOrgIdDirectionDirectionIdDelete(
        orgId: string,
        directionId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/org/{org_id}/direction/{direction_id}/',
            path: {
                'org_id': orgId,
                'direction_id': directionId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

}
