
<template>
    <!-- Окно добавления организации -->
    <div class="frame">
        <div class="card">

            <!-- Название организации -->
            <div class="field">
                <InputText id="nameOrg" type="text" v-model="nameOrg" placeholder="Название организации"
                    class="it-nameOrg" />
            </div>

            <!-- Адрес организации -->
            <div class="field">
                    <div class="flex">
                        <DropDown v-model="region" placeholder="Регион" :filter="true" :options="regionsStore.regions" optionLabel="name" class="address-inputs">
                        </DropDown>
                        <DropDown v-model="city" placeholder="Город" :filter="true" :options="citiesStore.cities" optionLabel="name" class="address-inputs">
                        </DropDown>
                        <InputText type="text" v-model="street" placeholder="Улица"
                    class="address-inputs" />
                    <InputText type="text" v-model="house" placeholder="Номер здания"
                    class="house-inputs" />
                    
                    </div>
            </div>

            <!-- Сайт организации -->
            <div class="field">
                <InputText id="urlOrg" type="text" v-model="urlOrg" placeholder="Сайт организации" class="it-nameOrg" />
            </div>

            <!-- Форма номера телефона -->
            <div class="field" v-if="isAddPhone == false">
                <InputText v-model="phoneNumber"  placeholder="Номер телефона"></InputText>
                <InputText type="text" v-model="phoneDescription" placeholder="Должность" class="ml-2"></InputText>
            </div>

            <!-- Почта организации -->
            <div class="field">
                <InputText type="text" v-model="emailValue" placeholder="Почта"></InputText>
            </div>

            <!-- Список образования -->
            <div class="field">
                <ListBox v-model="selectedDir" optionLabel="label" optionGroupLabel="label"
                    :options="levelStore.groupedTypes" optionGroupChildren="items" multiple class="lb-dir"
                    listStyle="max-height:250px;"></ListBox>
            </div>

        </div>

        <!-- Кнопка сохранить -->
        <div class="mt-6 btn-save-div">
            <p-btn label="Сохранить" class="ml-5 btn-save" @click="addOrg"></p-btn>
        </div>

    </div>
</template>

<script setup>
import { LMap, LTileLayer, LMarker, LPopup, } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import { useEduOrgStore } from '../stores/EduOrgStore'
import { useLevelsStore } from '../stores/LevelsStore'
import {useRegionStore} from '../stores/RegionStore'
import {useCitiesStore} from '../stores/CitiesStore'
import { ref } from 'vue'


const eduOrgs = useEduOrgStore()
const levelStore = useLevelsStore()
const regionsStore = useRegionStore()
const citiesStore = useCitiesStore()
// const i = true


/**
 * Приблежение карты
 */
const zoom = ref(5)
/**
 * Первоначальные координаты маркера
 */
const marker = ref([55.727, 37.573])
/**
 * Наименование организации
 */
const nameOrg = ref('')
/**
 * Адрес организации
 */
const region = ref('')

const city = ref()

const street = ref('')

const house = ref()


/**
 * Сайт организации
 */
const urlOrg = ref('')
/**
 * Широта маркера
 */
const lat = ref()
/**
 * Долгота маркера
 */
const lng = ref()

/**
 * Проверка на добавления телефона
 */
const isAddPhone = ref(false)
/**
 * Проверка на добавления почты
 */
const isAddEmail = ref(false)
/**
 * Номер телефона
 */
const phoneNumber = ref(null)
/**
 * Описание телефона
 */
const phoneDescription = ref(null)
/**
 * электронная почта
 */
const emailValue = ref('')
/**
 * Выбранные организации
 */
const selectedDir = ref()


/**
 * Добавление маркера на карту
 */
// function addMarker(e) {
//     // alert(e.latlng) 
//     marker.value = e.latlng
//     lat.value = marker.value["lat"]
//     lng.value = marker.value["lng"]
// }

/**
 *  Сохранение организации
 */
function addOrg() {
    let addressOrg = `${region.value.name}, ${city.value.name}, ${street.value}, д.${house.value}`
    let temp = city.value.coordinates.split(",")
    if (nameOrg.value != '' && addressOrg != '' && urlOrg.value != '') {
        eduOrgs.setOrg(addressOrg, temp[0], temp[1], nameOrg.value, urlOrg.value, phoneNumber.value, phoneDescription.value, emailValue.value, selectedDir.value)
        alert('Организация успешно добавлена')
    }
    else {
        alert("Пожалуйста заполните поля")
    }

}
</script>

<style scoped>
.it-nameOrg {
    width: 100%;
}

.lb-dir {
    max-width: 50%;
}
.address-inputs{
    width: 25%;
    margin-right: 10px;
}
.house-inputs{
    width: 25%;
}
</style>