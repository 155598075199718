import {defineStore} from "pinia";

export const useRegionStore = defineStore({
    id: 'Regions',
    state:()  => ({
        regions: [
         
            {
                "id": 80,
                "name": "Москва и Московская область",
                "district_id": 1
              },
              {
                "id": 81,
                "name": "Санкт-Петербург и область",
                "district_id": 3
              },
              {
                "id": 2,
                "name": "Республика Адыгея",
                "district_id": 2
              },
              {
                "id": 3,
                "name": "Алтайский край",
                "district_id": 5
              },
              {
                "id": 4,
                "name": "Амурская область",
                "district_id": 4
              },
              {
                "id": 5,
                "name": "Архангельская область",
                "district_id": 3
              },
              {
                "id": 6,
                "name": "Астраханская область",
                "district_id": 2
              },
              {
                "id": 7,
                "name": "Республика Башкортостан",
                "district_id": 7
              },
              {
                "id": 8,
                "name": "Белгородская область",
                "district_id": 1
              },
              {
                "id": 9,
                "name": "Брянская область",
                "district_id": 1
              },
              {
                "id": 10,
                "name": "Республика Бурятия",
                "district_id": 5
              },
              {
                "id": 11,
                "name": "Владимирская область",
                "district_id": 1
              },
              {
                "id": 12,
                "name": "Волгоградская область",
                "district_id": 2
              },
              {
                "id": 13,
                "name": "Вологодская область",
                "district_id": 3
              },
              {
                "id": 14,
                "name": "Воронежская область",
                "district_id": 1
              },
              {
                "id": 15,
                "name": "Республика Дагестан",
                "district_id": 8
              },
              {
                "id": 16,
                "name": "Еврейская АО",
                "district_id": 4
              },
              {
                "id": 17,
                "name": "Ивановская область",
                "district_id": 1
              },
              {
                "id": 18,
                "name": "Иркутская область",
                "district_id": 5
              },
              {
                "id": 19,
                "name": "Кабардино-Балкарская Республика",
                "district_id": 8
              },
              {
                "id": 20,
                "name": "Калининградская область",
                "district_id": 3
              },
              {
                "id": 21,
                "name": "Республика Калмыкия",
                "district_id": 2
              },
              {
                "id": 22,
                "name": "Калужская область",
                "district_id": 1
              },
              {
                "id": 23,
                "name": "Камчатский край",
                "district_id": 4
              },
              {
                "id": 24,
                "name": "Республика Карелия",
                "district_id": 3
              },
              {
                "id": 25,
                "name": "Кемеровская область",
                "district_id": 5
              },
              {
                "id": 26,
                "name": "Кировская область",
                "district_id": 7
              },
              {
                "id": 27,
                "name": "Республика Коми",
                "district_id": 3
              },
              {
                "id": 28,
                "name": "Костромская область",
                "district_id": 1
              },
              {
                "id": 29,
                "name": "Краснодарский край",
                "district_id": 2
              },
              {
                "id": 30,
                "name": "Красноярский край",
                "district_id": 5
              },
              {
                "id": 31,
                "name": "Курганская область",
                "district_id": 6
              },
              {
                "id": 32,
                "name": "Курская область",
                "district_id": 1
              },
              {
                "id": 33,
                "name": "Липецкая область",
                "district_id": 1
              },
              {
                "id": 34,
                "name": "Магаданская область",
                "district_id": 4
              },
              {
                "id": 35,
                "name": "Республика Марий Эл",
                "district_id": 7
              },
              {
                "id": 36,
                "name": "Республика Мордовия",
                "district_id": 7
              },
              {
                "id": 37,
                "name": "Мурманская область",
                "district_id": 3
              },
              {
                "id": 38,
                "name": "Нижегородская область",
                "district_id": 7
              },
              {
                "id": 39,
                "name": "Новгородская область",
                "district_id": 3
              },
              {
                "id": 40,
                "name": "Новосибирская область",
                "district_id": 5
              },
              {
                "id": 41,
                "name": "Омская область",
                "district_id": 5
              },
              {
                "id": 42,
                "name": "Оренбургская область",
                "district_id": 7
              },
              {
                "id": 43,
                "name": "Орловская область",
                "district_id": 1
              },
              {
                "id": 44,
                "name": "Пензенская область",
                "district_id": 7
              },
              {
                "id": 45,
                "name": "Пермский край",
                "district_id": 7
              },
              {
                "id": 46,
                "name": "Приморский край",
                "district_id": 4
              },
              {
                "id": 47,
                "name": "Псковская область",
                "district_id": 3
              },
              {
                "id": 48,
                "name": "Ростовская область",
                "district_id": 2
              },
              {
                "id": 49,
                "name": "Рязанская область",
                "district_id": 1
              },
              {
                "id": 50,
                "name": "Самарская область",
                "district_id": 7
              },
              {
                "id": 51,
                "name": "Саратовская область",
                "district_id": 7
              },
              {
                "id": 52,
                "name": "Республика Саха (Якутия)",
                "district_id": 4
              },
              {
                "id": 53,
                "name": "Сахалинская область",
                "district_id": 4
              },
              {
                "id": 54,
                "name": "Свердловская область",
                "district_id": 6
              },
              {
                "id": 55,
                "name": "Северная Осетия-Алания",
                "district_id": 8
              },
              {
                "id": 56,
                "name": "Смоленская область",
                "district_id": 1
              },
              {
                "id": 57,
                "name": "Ставропольский край",
                "district_id": 8
              },
              {
                "id": 58,
                "name": "Тамбовская область",
                "district_id": 1
              },
              {
                "id": 59,
                "name": "Республика Татарстан",
                "district_id": 7
              },
              {
                "id": 60,
                "name": "Тверская область",
                "district_id": 1
              },
              {
                "id": 61,
                "name": "Томская область",
                "district_id": 5
              },
              {
                "id": 62,
                "name": "Тувинская Республика",
                "district_id": 5
              },
              {
                "id": 63,
                "name": "Тульская область",
                "district_id": 1
              },
              {
                "id": 64,
                "name": "Тюменская область",
                "district_id": 6
              },
              {
                "id": 65,
                "name": "Республика Удмуртия",
                "district_id": 7
              },
              {
                "id": 66,
                "name": "Ульяновская область",
                "district_id": 7
              },
              {
                "id": 68,
                "name": "Хабаровский край",
                "district_id": 4
              },
              {
                "id": 69,
                "name": "Республика Хакасия",
                "district_id": 5
              },
              {
                "id": 70,
                "name": "Челябинская область",
                "district_id": 6
              },
              {
                "id": 71,
                "name": "Республика Ингушетия",
                "district_id": 8
              },
              {
                "id": 90,
                "name": "Чеченская республика",
                "district_id": 8
              },
              {
                "id": 72,
                "name": "Забайкальский край",
                "district_id": 5
              },
              {
                "id": 73,
                "name": "Республика Чувашия",
                "district_id": 7
              },
              {
                "id": 74,
                "name": "Чукотский АО",
                "district_id": 4
              },
              {
                "id": 75,
                "name": "Ямало-Ненецкий АО",
                "district_id": 6
              },
              {
                "id": 76,
                "name": "Ярославская область",
                "district_id": 1
              },
              {
                "id": 77,
                "name": "Карачаево-Черкесская Республика",
                "district_id": 8
              },
              {
                "id": 78,
                "name": "Ханты-Мансийский АО",
                "district_id": 6
              },

              {
                "id": 82,
                "name": "Республика Крым",
                "district_id": 1
              },
        ],
        
    }),
   
})