import { TokenService, PersonService } from "../client";
import axios from "axios";
import { defineStore } from "pinia";


interface forUserStore {
    /**
     * Токены пользователя
     */
    tokens: any,
    /**
     * Проверка на ошибку получения токенов
     */
    isErrorToken: boolean,
    /**
     * Проверка на авторизованость пользователя в системе 
     */
    isAuthPerson: boolean
    /**
     * Универсальный идентификатор пользователя
     */
    uid: string,
    /**
     * Имя пользователя
     */
    namePerson: string,
}

export const useUserStore = defineStore({
    id: 'Person',
    state: (): forUserStore => ({
        tokens: {},
        isErrorToken: false,
        uid: '',
        namePerson: '',
        isAuthPerson: false
    }),
    actions: {
        /**
         * Авторизация пользователя в системе
         * @param login логин пользователя
         * @param password пароль пользователя
         */
        loginPerson(login: string, password: string) {
            TokenService.getFullTokenTokenPost({ static_key: login, dynamic_key: password })
                .then(res => {
                    this.tokens = res
                    this.isAuthPerson = true
                    this.isErrorToken = false
                    PersonService.getCurrentPersonApiV1PersonCurrentGet()
                        .then(res => {
                            this.uid = res.id
                            this.namePerson = res.name
                            this.isAuthPerson = true
                        })
                        .catch(err => {
                            this.isAuthPerson = false
                            console.log(err);
                        })
                })
                .catch(err => {
                    this.isErrorToken = true
                    console.log(err);
                })

        },
        /**
         * Получение данных пользователя
         */
        fetchPerson() {
            PersonService.getCurrentPersonApiV1PersonCurrentGet()
                .then(res => {
                    this.uid = res.id
                    this.namePerson = res.name
                    this.isAuthPerson = true
                })
                .catch(err => {
                    this.isAuthPerson = false
                    console.log(err);
                })
        },
        /**
         * Выход пользователя из системы
         */
        signOutPerson() {
            const cName = "csrf_access_token"
            const nameLenPlus = (cName.length + 1);
            const cookies = document.cookie
                .split(';')
                .map(c => c.trim())
                .filter(cookie => {
                    return cookie.substring(0, nameLenPlus) === `${cName}=`;
                })
                .map(cookie => {
                    return decodeURIComponent(cookie.substring(nameLenPlus));
                })[0] || "";

            TokenService.deleteTokenTokenDelete(cookies)
                .then(res => {
                    this.isAuthPerson = false
                    this.tokens = []
                    this.namePerson = ''
                    this.uid = ''
                })
        },
        /**
         * Рефреш токена (функция не работает должным образом)
         */
        refreshToken() {
            // const cName = "x-csrf-token"
            // const nameLenPlus = (cName.length + 1);
            // const cookies = document.cookie
            //     .split(';')
            //     .map(c => c.trim())
            //     .filter(cookie => {
            //         return cookie.substring(0, nameLenPlus) === `${cName}=`;
            //     })
            //     .map(cookie => {
            //         return decodeURIComponent(cookie.substring(nameLenPlus));
            //     })[0] || "";

            const interceptor = axios.interceptors.response.use(
                response => response,
                error => {
                    if (error.response.status !== 401) {
                        return Promise.reject(error)
                    }
                    axios.interceptors.response.eject(interceptor);
                    return TokenService.getRefreshTokenTokenRefreshPost()
                        .then(res => {
                            console.log(res);
                            console.log('Токен обновился');
                            error.response.config.headers['Authorization'] = 'Bearer ' + res.access_token;
                            return axios(error.response.config);
                        })
                        .catch(error => {
                            console.log("токен не обновился");
                            return Promise.reject(error)
                        }).finally(this.refreshToken);
                }
            )
        },


    }
})