/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FullToken } from '../models/FullToken';
import type { Identity } from '../models/Identity';
import type { Token } from '../models/Token';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TokenService {

    /**
     * Get Full Token
     * Get full token.
     * @param requestBody
     * @returns FullToken Successful Response
     * @throws ApiError
     */
    public static getFullTokenTokenPost(
        requestBody: Identity,
    ): CancelablePromise<FullToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Token
     * Delete token.
     * @param xCsrfToken
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTokenTokenDelete(
        xCsrfToken?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/token/',
            headers: {
                'x-csrf-token': xCsrfToken,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Fresh Token
     * Get fresh token.
     * @param requestBody
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static getFreshTokenTokenFreshPost(
        requestBody: Identity,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/fresh/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Refresh Token
     * Get refresh token.
     * @param xCsrfToken
     * @returns Token Successful Response
     * @throws ApiError
     */
    public static getRefreshTokenTokenRefreshPost(
        xCsrfToken?: string,
    ): CancelablePromise<Token> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/token/refresh/',
            headers: {
                'x-csrf-token': xCsrfToken,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

}
