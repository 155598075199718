<template>
  <div style="height: 70vh; width: 100%;" class="mb-10">
    <l-map v-model="zoom" :zoom="zoom" :center="[66.65, 107.23]">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png">
      </l-tile-layer>
      <div v-for="orgs in markerList" :key="orgs.id">
        <l-marker :lat-lng="[orgs.latitude, orgs.longitude]" v-if="orgs.latitude != null"
          @click="getListOrgs(orgs.latitude, orgs.longitude)">
          <l-icon :icon-url="iconUrl" :icon-size="iconSize" />
        </l-marker>
      </div>
    </l-map>
    <SideBar v-model:visible="visibleLeft" class="p-sidebar-md">
      <template #header>
        <div class="divTitle">
          <h3 class="titleSide">{{ cityName }}</h3>
        </div>
      </template>
      <DataTable :value="dataMarker">
        <tColumn field="name" header="Название"></tColumn>
        <tColumn field="address" header="Адрес"></tColumn>
        <tColumn>
          <template #body="slotProps">
            <p-btn class="p-button-rounded" style="background-color: rgb(35, 151, 243);" label="Подробнее"
              @click="openDialog(slotProps.data.id)"></p-btn>
          </template>
        </tColumn>
      </DataTable>
    </SideBar>

    <pv-Dialog position="center" v-model:visible="EduInfo" :modal="true" :style="{ width: '90%',  }">
      <template #header>
        <h2>Организация: {{ eduOrgStore.searchingEdu[0].name }}</h2>
        <div class="card">
        </div>
      </template>
      <div class="card">
      <ShowOrgInfo></ShowOrgInfo></div>
    </pv-Dialog>



  </div>
</template>
<script setup>
import { LMap, LTileLayer, LMarker, LPopup, LGeosearch, LIcon } from "@vue-leaflet/vue-leaflet";

import "leaflet-geosearch/dist/geosearch.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import teamwork from '../assets/teamwork.png'
import "leaflet/dist/leaflet.css";
import { useEduOrgStore } from '@/stores/EduOrgStore'
import { useUserStore } from '@/stores/UserStore'
import { ref, ComputedRef, computed } from 'vue'
import ShowOrgInfo from './ShowOrgInfo.vue'
// import { latLng } from "leaflet";

const eduOrgStore = useEduOrgStore()
const userStore = useUserStore()
const UnikMarkers = ref([])
const dataMarker = ref([])
const visibleLeft = ref(false)
const cityName = ref('')
const EduInfo = ref(false);
const isEditInfo = ref(false)


const iconUrl = computed(() => {
  return require('@/assets/orgs48.png')
})
const iconSize = computed(() => {
  return [20, 30]
})

/**
 * Приближение карты
 */
const zoom = ref(3)

// const nameAndCountry: ComputedRef<string> = computed((): string => `The movie name is ${movieName.value} from ${country.value}`);
const markerList = computed(() => {
  let list = eduOrgStore.eduOrgs
  list = list.filter((first, index, array) => list.findIndex((second, secondIndex) => second.latitude === first.latitude && second.longitude == first.longitude && secondIndex > index) === -1)
  console.log(list)



  return list
})

const openDialogEduInfo = () => {
  EduInfo.value = true
}

function getListOrgs(lat, lng) {
  // console.log("govno")
  let list = eduOrgStore.eduOrgs
  list = list.filter((orgs) => orgs.latitude == lat && orgs.longitude == lng)
  dataMarker.value = list
  visibleLeft.value = true
  console.log(dataMarker.value)
  let arr = dataMarker.value[0].address.split(',')
  cityName.value = arr[1]
}

function openDialog(idOrg) {
  eduOrgStore.findEdu(idOrg)
  console.log(idOrg)
  openDialogEduInfo()
}

const closeDialogEduInfo = () => {
  EduInfo.value = false
}

</script>

<style scoped>
.grid-item {
  margin-left: auto;
  margin-right: auto;
}

.titleSide {
  margin-right: 10px;

}

.divTitle {
  width: 100%;
}
</style>