import { defineStore } from "pinia";
import { useCitiesStore } from "./CitiesStore";
import { LevelService, OrganizationService } from "../client";

interface forEduOrgStore {
    /**
     * Список всех организаций 
     */
    eduOrgs: any[];
    /**
     * Искомая организация для отображения в подробном
     */
    searchingEdu: any;
    /**
     * ID искомой организации
     */
    idOrg: string;

    /**
     * Временный массив для хранения результата фильтрации
     */
    filterdByDirsList: any[];

    coords: string[];

    loading: boolean;


}

export const useEduOrgStore = defineStore({
    id: 'EduOrg',
    state: (): forEduOrgStore => ({
        eduOrgs: [],
        searchingEdu: {},
        idOrg: '',
        filterdByDirsList: [],
        loading: true,
        coords:['']
    }),
    actions: {
        /**
         * Получение списка организаций
         */
        getOrgs() {
            OrganizationService.getOrgsApiV1OrgGet()
                .then(res => {
                    this.eduOrgs = res
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                })
        },
        /**
         * Добавление организации
         * @param address адрес организации
         * @param lat широта организации 
         * @param lng долгота организации
         * @param name наименование организации
         * @param url сайт организации
         * @param numberPhone номер телефона 
         * @param desPhone описание номера телефона
         * @param emailValue электронная почта 
         * @param directions образовательные организации
         */
        setOrg(address: string, lat: string, lng: string, name: string, url: string, numberPhone: string, desPhone: string, emailValue: string, directions: any[]) {
            OrganizationService.setOrgApiV1OrgPost({ address: address, latitude: lat, longitude: lng, name: name, url: url })
                .then(res => {
                    this.eduOrgs.push(res)
                    if (numberPhone != null && desPhone != null) {
                        OrganizationService.setPhoneApiV1OrgOrgIdPhonePost(res.id, { value: numberPhone, description: desPhone, org_id: res.id })
                            .then(resPhone => {
                                console.log('Телефон успешно привязался')
                                console.log(resPhone)
                            })
                    }
                
                    if (emailValue != null) {
                        OrganizationService.setEmailApiV1OrgOrgIdEmailPost(res.id, { value: emailValue })
                            .then(resEmail => {
                                console.log('Добавлена почта');
                                console.log(resEmail);
                            })
                    }
                    if (directions.length > 0) {
                        directions.forEach(dir => {
                            OrganizationService.setDirectionInOrgApiV1OrgOrgIdDirectionDirectionIdPost(res.id, dir.value)
                                .then(resAddDie => {
                                    console.log("Образования успешно добавились");
                                    console.log(resAddDie);


                                })
                        })
                    }

                })
                .catch(err => {
                    console.log(err);
                    console.log(address);
                    console.log("Супер ошибка")


                })
        },
        /**
         * удаление организации
         * @param idOrg ID организации
         */
        deleteOrg(idOrg: string) {
            OrganizationService.unsetOrgApiV1OrgOrgIdDelete(idOrg)
            
                .then(res => {
                    console.log('Удаление успешно');
                    console.log(res);
                    const index = this.eduOrgs.findIndex((el) => el.id == res.id)
                    this.eduOrgs.splice(index, 1)
                })
                .catch(err => {
                    console.log(err);
                })
        },
        /**
         * Поиск необходимой организации
         * @param item Организация как обьект
         */
        findEdu(item: any) {
            this.searchingEdu = this.eduOrgs.filter((e) => e.id == item)
            if (this.searchingEdu[0].latitude == null && this.searchingEdu[0].longitude == null) {
                this.searchingEdu[0].latitude = 55.81434
                this.searchingEdu[0].longitude = 37.63251
            }
        },
        /**
         * Добавление телефона к организации
         * @param number номер телефона
         * @param description описание телефона
         */
        addOrgPhone(number: any, description: string) {
            if (number.length != 0) {
                OrganizationService.setPhoneApiV1OrgOrgIdPhonePost(this.idOrg, { value: number, description: description, org_id: this.idOrg })
                    .then(res => {
                        console.log('Телефон успешно привязался')
                        console.log(res)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        },
        /**
         * Редактирование организации
         * @param id ID организации
         * @param address адрес организации
         * @param lat ширина организации
         * @param lng долгота организации
         * @param name наименование организации
         * @param url сайт организации
         */
        putOrgInto(id: string, address: string, lat: string, lng: string, name: string, url: string) {
            const citis = useCitiesStore().cities
            const arrAddress = address.split(", ")
            
            citis.forEach(city => {

                for(let i =0; i<= arrAddress.length; i++){
                    if(city.name.indexOf(arrAddress[i]) !== -1){
                        this.coords = city.coordinates.split(",")
                    }
                }
            
            })
            const latitude = this.coords[0]
            const longitude = this.coords[1]
           

            OrganizationService.setOrgByIdApiV1OrgOrgIdPut(id, { address: address, latitude: latitude, longitude: longitude, name: name, url: url }).then(res => {
                console.log(res);
                this.searchingEdu[0] = res
                const index = this.eduOrgs.findIndex((el) => el.id == id)
                this.eduOrgs[index] = res
            })
                .catch(err => {
                    console.log(err);
                })
        },
        /**
         * Создание электронной почты организации
         * @param id ID организации
         * @param emailValue электронная почта организации
         */
        setEmailInOrg(id: string, emailValue: string) {
           if(emailValue.length != 0){
            OrganizationService.setEmailApiV1OrgOrgIdEmailPost(id, { value: emailValue })
            .then(resEmail => {
                console.log('Добавлена почта');
                console.log(resEmail);
                this.searchingEdu[0].emails.push(resEmail)
                
            })
           }
        },
        /**
         * Удаление электронной почты организации
         * @param idOrg ID организации
         * @param idEmail ID электроной почты организации
         */
        deleteEmailInOrg(idOrg: string, idEmail: string) {
            OrganizationService.unsetEmailApiV1OrgOrgIdEmailEmailIdDelete(idOrg, idEmail)
                .then(res => {
                    console.log('Удалена почта')
                    const index = this.searchingEdu[0].emails.findIndex((el: any) => el.id == idEmail)
                    this.searchingEdu[0].emails.splice(index, 1)
                })
        },
        /**
         * Создание номера телефона организации
         * @param idOrg  ID организации
         * @param value номер телефона
         * @param desc описание телефона
         */
        setPhoneInOrg(idOrg: string, value: string, desc: string) {
          if(value.length != 0){
            OrganizationService.setPhoneApiV1OrgOrgIdPhonePost(idOrg, { value: value, description: desc, org_id: idOrg })
            .then(resPhone => {
                console.log('Телефон успешно привязался')
                console.log(resPhone)
                this.searchingEdu[0].phones.push(resPhone)
            })
          }
        },
        /**
         * Удаление номера телефона организации
         * @param idOrg ID организации
         * @param idPhone ID телефона
         */
        deletePhoneInOrg(idOrg: string, idPhone: string) {
            OrganizationService.unsetPhoneApiV1OrgOrgIdPhonePhoneIdDelete(idOrg, idPhone)
                .then(res => {
                    const index = this.searchingEdu[0].phones.findIndex((el: any) => el.id == idPhone)
                    this.searchingEdu[0].phones.splice(index, 1)
                    console.log(res)
                })
        },
        /**
         * Создание образовательного направления
         * @param idOrg ID организации
         * @param dir список выбранных направлений
         */
        setDirinOrg(idOrg: string, dir: any[]) {
            if(dir.length != 0){
                if (dir.length > 0) {
                    dir.forEach(item => {
                        OrganizationService.setDirectionInOrgApiV1OrgOrgIdDirectionDirectionIdPost(idOrg, item.value)
                            .then(resAddDie => {
                                console.log("Образования успешно добавились");
                                console.log(resAddDie);
                                const itemDir = {name: item.label}
                                this.searchingEdu[0].directions.push(itemDir)
                            })
                    })
                }
            }
        },
        /**
         * Удаление направления из организации
         * @param idOrg ID организации
         * @param idDir ID образовательного направления
         */
        deleteDirInOrg(idOrg: string, idDir: string) {
            OrganizationService.unsetDirectionFromOrgApiV1OrgOrgIdDirectionDirectionIdDelete(idOrg, idDir)
                .then(res => {
                    console.log(res)
                    const index = this.searchingEdu[0].directions.findIndex((el: any) => el.id == idDir)
                    this.searchingEdu[0].directions.splice(index, 1)
                    console.log(res)
                })

        },
        /**
         * Фильтрация организаций по типу образования
         * @param dirs выбранные образовательные направления
         */
        filterdByDirOrg(dirs: any[], regions: any[]) {
            this.loading = true
            this.filterdByDirsList = []
            if (dirs.length != 0 || regions.length != 0) {
                if (dirs.length != 0) {
                    dirs.forEach(itemDir => {
                        console.log(itemDir);
                        this.eduOrgs.forEach(itemOrg => {
                            console.log(itemOrg.directions)
                            itemOrg.directions.forEach((itemDirInOrg: { id: string; }) => {
                                console.log(itemDirInOrg);
                                if (itemDirInOrg.id == itemDir.value) {
                                    this.filterdByDirsList.push(itemOrg)
                                }
                            })
                        })
                    })

                }
                if (regions.length != 0) {
                    regions.forEach(itemReg => {
                        this.eduOrgs.forEach(itemOrgs => {
                            if (itemOrgs.address != null) {
                                if (itemOrgs.address.indexOf(itemReg.name) !== -1) {
                                    console.log(itemReg.name);
                                    this.filterdByDirsList.push(itemOrgs)
                                }
                            }
                        })
                    })

                }
                this.loading = false
                const arrFilter = [...new Set(this.filterdByDirsList)]
                this.eduOrgs = arrFilter
                
            } else {
                OrganizationService.getOrgsApiV1OrgGet()
                    .then(res => {
                        this.eduOrgs = res
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }


        },
        getOrgsForMCHS(){
            OrganizationService.getOrgsApiV1OrgGet()
                .then(res => {
                    this.eduOrgs = res
                    this.loading = false
                })
                .catch(err => {
                    console.log(err);
                })
            this.filterdByDirsList = []
            OrganizationService.getOrgsApiV1OrgGet()
                    .then(res => {
                        // this.eduOrgs = res
                        this.loading = false
                        res.forEach(org => {
                            if(org.name.indexOf("ФПС") !== -1 || org.name.indexOf("МЧС") !== -1){
                                this.filterdByDirsList.push(org)
                            }
                            
                        });
                        const arrFilter = [...new Set(this.filterdByDirsList)]
                        this.eduOrgs = arrFilter
                        this.loading = false

                    })
                    .catch(err => {
                        console.log(err);
                    })
        }


    },

})

