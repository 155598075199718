<template>
  <div>
    <!-- Вверхняя панель -->
    <MenuBar :model="menubarText" class="menu-bar">
      <template #start>
        <img alt="logo" src="https://experts.spkchs.ru/logo.png" class="img_logo" @click="goHome" />
      </template>
      <template #item>
        <div class="ml-3 title-site">
          Реестр образовательных организаций СПК ЧС
        </div>
      </template>
      <template #end>
        <div v-if="userStore.isAuthPerson == false">
          <p-btn class="mr-3 btn-SignIn" label="Войти" @click="openDialogSignIn"></p-btn>
        </div>
        <div v-if="userStore.isAuthPerson == true">
          <div class="mr-3 title-site">
            {{ userStore.namePerson }}
            <i class="pi pi-sign-out ml-3 icon-out" style="font-size: 1rem" @click="signOut()"></i>
          </div>
        </div>
      </template>
    </MenuBar>
    <!-- Навигация -->
    <router-view />
    <!-- Диалоговое окно авторизации -->
    <pv-Dialog position="center" v-model:visible="display" header="Авторизация" :modal="true">
      <div class="card">
        <div v-if="userStore.isErrorToken == true">
          <div class="field">
            <InputText id="username" type="text" v-model="login" class="signIn-inputs p-invalid" placeholder="Логин" />
          </div>
          <div class="field">
            <InputText type="password" v-model="password" class="signIn-inputs p-invalid" placeholder="Пароль" />
          </div>
        </div>
        <div v-if="userStore.isErrorToken == false">
          <div class="field">
            <InputText id="username" type="text" v-model="login" class="signIn-inputs" placeholder="Логин" />
          </div>
          <div class="field">
            <InputText type="password" v-model="password" class="signIn-inputs" placeholder="Пароль" />
          </div>
        </div>
      </div>
      <template #footer>
        <p-btn label="Войти" icon="pi pi-check" @click="logIn()" autofocus
          style="background-color: rgb(35, 151, 243);" />
      </template>
    </pv-Dialog>
  </div>
</template>

<script setup>
import router from "./router";
import { ref } from 'vue'
import {useUserStore} from '@/stores/UserStore'
import {useRegionStore} from '@/stores/RegionStore'
import { TokenService, PersonService } from "./client";
import axios from "axios";

const userStore = useUserStore();
const regions = useRegionStore()
const display = ref(false);
const login = ref('');
const password = ref('');

userStore.fetchPerson()
// userStore.refreshToken();

// function createAxiosResponseInterceptor(){

  
// }

// userStore.refreshToken()





const openDialogSignIn = () => {
  display.value = true;
}
const closeDialogSignIn = () => {
  display.value = false;
}

const menubarText = [{
  label: "Реестр образовательных организаций"
}]

function goHome() {
  router.push("/")
}

function logIn() {
  userStore.loginPerson(login.value, password.value)
  
  setTimeout(closeDialogSignIn(), 1000)
  userStore.fetchPerson()
  
}
function signOut() {
  userStore.signOutPerson()
}

</script>

<style scoped>
.title-site {
  font-size: 20px;
  font-weight: 200;
}

.menu-bar {
  background-color: rgb(35, 151, 243);
  color: white;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}

.btn-SignIn {
  color: white;
  background-color: transparent;
  border-color: white;
}

.btn-SignIn:hover {
  background-color: rgb(107, 181, 242);
}

.icon-out:hover {
  cursor: pointer;
}

.img_logo {
  width: 50px;
}

.img_logo:hover {
  cursor: pointer;
}

.btn-signOut {
  background-color: transparent;
}

.signIn-inputs {
  max-width: 500px;
  min-width: 100px;
  width: 400px;
}
</style>