import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { loadFonts } from './plugins/webfontloader';


import PrimeVue from 'primevue/config';
import Menubar from 'primevue/menubar';
import Button from 'primevue/button'
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row'; 
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import SelectButton from 'primevue/selectbutton';
import Listbox from 'primevue/listbox';
import InputMask from 'primevue/inputmask';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import VirtualScroller from 'primevue/virtualscroller';
import ScrollPanel from 'primevue/scrollpanel';
import Sidebar from 'primevue/sidebar';



import '@/assets/main.scss'

import "primevue/resources/themes/lara-light-blue/theme.css"   //theme
import "primevue/resources/primevue.min.css"                 //core css
import "primeicons/primeicons.css" 
import "primeflex/primeflex.css"

loadFonts()



const app = createApp(App);
app.use(router);
app.use(PrimeVue)
app.use(createPinia());
app.component("MenuBar", Menubar)
app.component("p-btn", Button)
app.component("p-Card", Card)
app.component("InputText", InputText)
app.component("MultiSelect", MultiSelect)
app.component("pv-Dialog", Dialog)
app.component("pv-Divider", Divider)
app.component("SelectButton", SelectButton)
app.component("ListBox", Listbox)
app.component("InputMask", InputMask)
app.component("DropDown", Dropdown)
app.component("CheckBox", Checkbox)
app.component("VirtualScroller", VirtualScroller)
app.component("ScrollPanel", ScrollPanel)
app.component("SideBar", Sidebar)

/**
 * DataTable
 */
 app.component("DataTable", DataTable)
 app.component("tColumn", Column)
 app.component("ColumnGroup", ColumnGroup)
 app.component("tRow", Row)

app.mount("#app");
