/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Level } from '../models/Level';
import type { LevelModel } from '../models/LevelModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LevelService {

    /**
     * Get Levels
     * Получение всех уровней.
     * @returns Level Successful Response
     * @throws ApiError
     */
    public static getLevelsApiV1LevelGet(): CancelablePromise<Array<Level>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/level/',
        });
    }

    /**
     * Set Level
     * Создание уровня.
     * @param requestBody
     * @returns Level Successful Response
     * @throws ApiError
     */
    public static setLevelApiV1LevelPost(
        requestBody: LevelModel,
    ): CancelablePromise<Level> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/level/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Level By Id
     * Редактироване уровня.
     * @param levelId
     * @param requestBody
     * @returns Level Successful Response
     * @throws ApiError
     */
    public static setLevelByIdApiV1LevelLevelIdPut(
        levelId: string,
        requestBody: LevelModel,
    ): CancelablePromise<Level> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/level/{level_id}/',
            path: {
                'level_id': levelId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unset Level
     * Удаление уровня.
     * @param levelId
     * @returns Level Successful Response
     * @throws ApiError
     */
    public static unsetLevelApiV1LevelLevelIdDelete(
        levelId: string,
    ): CancelablePromise<Level> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/level/{level_id}/',
            path: {
                'level_id': levelId,
            },
            errors: {
                401: `Authorization failed`,
                422: `Validation Error`,
            },
        });
    }

}
